var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'issue-token gl-inline-flex gl-align-items-stretch gl-max-w-full gl-leading-24 gl-whitespace-nowrap':
      _vm.isCondensed,
    'flex-row issuable-info-container': !_vm.isCondensed,
  }},[_c(_vm.computedLinkElementType,{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],ref:"link",tag:"component",class:{
      'issue-token-link gl-inline-flex gl-min-w-0 gl-text-gray-500': _vm.isCondensed,
      'issuable-main-info': !_vm.isCondensed,
    },attrs:{"href":_vm.computedPath,"title":_vm.issueTitle,"data-placement":"top"}},[(_vm.hasTitle)?_c(_vm.innerComponentType,{ref:"title",tag:"component",staticClass:"js-issue-token-title",class:{
        'issue-token-title issue-token-end gl-overflow-hidden gl-display-flex gl-align-items-baseline gl-text-gray-500 gl-pl-3':
          _vm.isCondensed,
        'issue-title block-truncated': !_vm.isCondensed,
        'gl-rounded-top-right-small gl-rounded-bottom-right-small gl-pr-3': !_vm.canRemove,
      }},[_c('span',{staticClass:"gl-text-truncate"},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_vm._v(" "),_c(_vm.innerComponentType,{ref:"reference",tag:"component",class:{
        'issue-token-reference gl-display-flex gl-align-items-center gl-rounded-top-left-small gl-rounded-bottom-left-small gl-px-3':
          _vm.isCondensed,
        'issuable-info': !_vm.isCondensed,
      }},[(_vm.hasState)?_c('gl-icon',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],class:_vm.iconClass,attrs:{"name":_vm.iconName,"size":12,"title":_vm.stateTitle,"aria-label":_vm.state,"data-testid":"referenceIcon"}}):_vm._e(),_vm._v("\n      "+_vm._s(_vm.displayReference)+"\n    ")],1)],1),_vm._v(" "),(_vm.canRemove)?_c('button',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],ref:"removeButton",class:{
      'issue-token-remove-button gl-display-flex gl-align-items-center gl-px-3 gl-border-0 gl-rounded-top-right-small gl-rounded-bottom-right-small gl-text-gray-500':
        _vm.isCondensed,
      'btn btn-default': !_vm.isCondensed,
    },attrs:{"title":_vm.removeButtonLabel,"aria-label":_vm.removeButtonLabel,"disabled":_vm.removeDisabled,"data-testid":"removeBtn","type":"button"},on:{"click":_vm.onRemoveRequest}},[_c('gl-icon',{attrs:{"name":"close"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }